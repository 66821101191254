import { Field, FieldArray, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { Button, Col, Container, Form as ThemedForm, Row } from "react-bootstrap";
import { useHistory } from 'react-router-dom';
import Uppy from '@uppy/core';
import { Dashboard } from '@uppy/react';
import Swal from "sweetalert2";
import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import { default as ValidationForm } from '../../Form';
import { apiServerURL, libraryServerURL, authenticationServerURL } from "../../../config/api";
import enelLogo from '../../../assets/enel-logo.png';

// const contractFormSchema = Yup.object().shape({
//     plico: Yup.string().required('Plico is required.'),  
//     cs: Yup.boolean(),
//     fatturazione: Yup.boolean(),
//     rid: Yup.boolean(),
//     bw: Yup.boolean(),
//     ev: Yup.boolean(),
//     contract: Yup.string(),
//     note: Yup.string(),
//     fornituras: Yup.mixed()
//         .when('tipologia', (value) => {
//             if(value === 'business') {
//                 return Yup.array().of(
//                     Yup.object().shape({
//                         piva: Yup.string().required("P.IVA is required when tipologia is business."),
//                         provincia: Yup.string().required("Provincia is required."),
//                         telephone1: Yup.string().when('telephone2', {
//                             is: (telephone2) => !telephone2 || telephone2.length === 0,
//                             then: Yup.string().required('One of the two telephone numbers is required.'),
//                             otherwise: Yup.string()
//                         }),
//                         kw: Yup.number().when('purpose', {
//                             is: 'luce',
//                             then: Yup.number().required('KW is required.'),
//                             otherwise: Yup.number()
//                         }),
//                         pod: Yup.string().when('purpose', {
//                             is: 'luce',
//                             then: Yup.string().required('POD is required.'),
//                             otherwise: Yup.string()
//                         }),
//                         pdr: Yup.string().when('purpose', {
//                             is: 'gas',
//                             then: Yup.string().required('PDR is required.'),
//                             otherwise: Yup.string()
//                         }),
//                         consumoAnnuo: Yup.string().required('Consumo Annuo is required.'),
//                         offerta: Yup.string().required('Offerta is required.'),
//                         cap: Yup.string(),
//                         indirizzo: Yup.string(),
//                         purpose: Yup.string(),
//                         telephone2: Yup.string(),
//                         mc: Yup.string(),
//                         pdr: Yup.string(),
//                         nomeCognome: Yup.string(),
//                         denominazione: Yup.string(),
//                         codiceFiscale: Yup.string()
//                     })
//                 );
//             }
//             if(value === 'consumer') {
//                 return Yup.array().of(
//                     Yup.object().shape({
//                         codiceFiscale: Yup.string().required("Codice Fiscale is required when tipologia is consumer."),
//                         provincia: Yup.string().required("Provincia is required."),
//                         telephone1: Yup.string().when('telephone2', {
//                             is: (telephone2) => !telephone2 || telephone2.length === 0,
//                             then: Yup.string().required('One of the two telephone numbers is required.'),
//                             otherwise: Yup.string()
//                         }),
//                         kw: Yup.number().when('purpose', {
//                             is: 'luce',
//                             then: Yup.number().required('KW is required.'),
//                             otherwise: Yup.number()
//                         }),
//                         pod: Yup.string().when('purpose', {
//                             is: 'luce',
//                             then: Yup.string().required('POD is required.'),
//                             otherwise: Yup.string()
//                         }),
//                         pdr: Yup.string().when('purpose', {
//                             is: 'gas',
//                             then: Yup.string().required('PDR is required.'),
//                             otherwise: Yup.string()
//                         }),
//                         consumoAnnuo: Yup.string().required('Consumo Annuo is required.'),
//                         offerta: Yup.string().required('Offerta is required.'),
//                         cap: Yup.string(),
//                         indirizzo: Yup.string(),
//                         purpose: Yup.string(),
//                         telephone2: Yup.string(),
//                         mc: Yup.string(),
//                         nomeCognome: Yup.string(),
//                         denominazione: Yup.string(),
//                         piva: Yup.string()
//                     })
//                 );
//             }
//         })
// });


const uppy = new Uppy({
    debug: process.env.NODE_ENV === "development" ?? false,
    autoProceed: false,
    allowMultipleUploads: true,
    restrictions: {
        minNumberOfFiles: 1
    }
});


export default function Enel() {

    const history = useHistory();

    const initialValues = {
        tipologia: "",
        fornituras: [
            {
                cap: "",
                provincia: "",
                consumoAnnuo: "",
                indirizzo: "",
                kw: "",
                offerta: "",
                piva: "",
                pod: "",
                purpose: "",
                telephone1: "",
                telephone2: "",
                mc: "",
                pdr: "",
                nomeCognome: "",
                denominazione: "",
                codiceFiscale: "",


                isDigital: false,
                dob: "",
                birthPlace: "",
                document: "",
                documentNumber: "",
                releaseDate: "",
                expirationDate: "",
                currentSupplier: "",
                residentialAddress: "",
                propertyOwnership: "",
                resident: false,
                currentAccountHolder: "",
                ibanCoordinates: "",
                tipologiaSocieta: "",
                email: ""


            }
        ],
        plico: "",
        cs: false,
        fatturazione: false,
        rid: false,
        bw: false,
        ev: false,
        contract: '',
        note: ''
    };

    const token = JSON.parse(localStorage.getItem('accessToken')).accessToken;

    const [offers, setOffers] = useState([]);

    useEffect( () => {
        (async () => {
            try {
                const offersAPICall = await fetch(`${apiServerURL}/offerte/ENEL`, {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                });

                const offersAPICallResponse = await offersAPICall.json();

                if(!offersAPICall.ok) {
                    throw new Error(offersAPICallResponse.message || `An error occurred while fetching offers.`);
                }

                setOffers(offersAPICallResponse.offers);
            } catch(e) {
                console.error(e);
            }
        })();
    }, [token]);

    const handleFormSubmission = async (values, actions) => {
        try {
            if(token) {
                const preflightRequest = await fetch(`${authenticationServerURL}/oauth/verify/token`, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        token
                    })
                });
                const preflightRequestData = await preflightRequest.json();
                if(!preflightRequestData.valid) {
                    localStorage.removeItem('accessToken');
                    return history.push('/');
                }
            }

            const valuesModified = Object.entries(values).map( value => {
                const key = value[0], val = value[1];
                if(typeof(val) === "boolean") {
                    if(val === false) {
                        return [ key, "no" ];
                    } else {
                        return [ key, "si" ];
                    }
                }
                return [ key, val ];
            });
            values = Object.fromEntries(valuesModified);
            const contractFormData = new FormData();
            uppy.getFiles().map( file => {
                return contractFormData.append("files", file.data);
            });
            const contractUploadAPICall = await fetch(libraryServerURL, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                body: contractFormData
            });

            const contractUploadAPICallResponse = await contractUploadAPICall.json();

            if(!contractUploadAPICall.ok) {
                throw new Error(contractUploadAPICallResponse.message || `An error occurred while uploading the contract.`);
            }


            values.contracts = contractUploadAPICallResponse.files.map( x => x.filename );

            // const contractFile = contractUploadAPICallResponse.files[0].filename;
            // values.contract = contractFile;
                        
            const formSavingAPICall = await fetch(`${apiServerURL}/contracts/enel`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(values)
            });

            const formSavingAPICallResponse = await formSavingAPICall.json();

            if(!formSavingAPICall.ok) {
                throw new Error(formSavingAPICallResponse.message || `An error occurred while uploading the contract.`);
            }
            
            uppy.reset();

            Swal.fire("Successo", formSavingAPICallResponse.message, "success");
            actions.resetForm();
        } catch(e) {
            console.error(e);
            Swal.fire("Errore", e.message, "error");
        }
    }

    // const handleContractUpload = (event, setFieldValue) => {
    //     const files = event.target.files;
    //     if(files.length > 0) {
    //         setFieldValue('contract', files[0]);
    //     }
    // }

    return (
        <ValidationForm name="Enel" logo={enelLogo}>
            <Formik
                initialValues={initialValues}
                // validationSchema={contractFormSchema}
                onSubmit={handleFormSubmission}
            >
                {
                    ({ values, errors, touched, setFieldValue }) => (
                        <Form>
                            {/* <div className="logo"></div> */}
                            <Container fluid className="p-0">
                                {/* { error ? <div class="alert alert-danger" role="alert">{error}</div> : null }
                                { message ? <div class="alert alert-success" role="alert">{message}</div> : null } */}
                                <Row className="py-2">
                                    <Col>
                                        <Field as="select" className="form-control" name="tipologia">
                                            <option value="">Tipologia Cliente</option>
                                            <option value="business">Business</option>
                                            <option value="consumer">Consumer</option>
                                        </Field>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <FieldArray name="fornituras">
                                        {
                                            ({ insert, remove, push }) => (
                                                <div>
                                                    { values.fornituras.length > 0 && !!values.tipologia &&
                                                        values.fornituras.map( (fornitura, index) => {
                                                            return (
                                                                <div className="p-4 mx-0 border border-primary mb-3 rounded-3" key={index}>
                                                                    

                                                                    <Row className="align-items-center">
                                                                        <Col>
                                                                            <h1>Fornitura {values.tipologia === "business" ? "Business" : "Consumer"}</h1>
                                                                        </Col>
                                                                        <Col className="text-end">
                                                                            <span onClick={() => remove(index)}>&times;</span>
                                                                        </Col>
                                                                    </Row>


                                                                    <div className="mb-2">
                                                                        <Field
                                                                            as="select"
                                                                            className="form-control"
                                                                            name={`fornituras.${index}.purpose`}
                                                                        >
                                                                            <option value="">Tipologia Fornitura</option>
                                                                            <option value="gas">Gas</option>
                                                                            <option value="luce">Power</option>
                                                                        </Field>
                                                                    </div>


                                                                    
                                                                    {
                                                                        values.fornituras[index].purpose === "gas" &&
                                                                        <>
                                                                            <div className="mb-2">
                                                                                <div className="form-group">
                                                                                    <Field as={ThemedForm.Control} type="text" name={`fornituras.${index}.pdr`} placeholder="PDR" />
                                                                                    {touched.fornituras?.[index]?.pdr && errors.fornituras?.[index]?.pdr && <div className="invalid-feedback d-block">{errors.fornituras?.[index]?.pdr}</div>}
                                                                                </div>
                                                                            </div>
                                                                            <div className="mb-2">
                                                                                <div className="form-group">
                                                                                    <Field as={ThemedForm.Control} type="number" name={`fornituras.${index}.mc`} placeholder="MC" />
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    }
                                                                    
                                                                    {
                                                                        values.fornituras[index].purpose === "luce" &&
                                                                        <>
                                                                            <div className="mb-2">
                                                                                <div className="form-group">
                                                                                    <Field as={ThemedForm.Control} type="text" name={`fornituras.${index}.pod`} placeholder="POD" />
                                                                                    {touched.fornituras?.[index]?.pod && errors.fornituras?.[index]?.pod && <div className="invalid-feedback d-block">{errors.fornituras?.[index]?.pod}</div>}
                                                                                </div>
                                                                            </div>
                                                                            <div className="mb-2">
                                                                                <div className="form-group">
                                                                                    <Field as={ThemedForm.Control} type="number" name={`fornituras.${index}.kw`} placeholder="KW" />
                                                                                    {touched.fornituras?.[index]?.kw && errors.fornituras?.[index]?.kw && <div className="invalid-feedback d-block">{errors.fornituras?.[index]?.kw}</div>}
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    }

                                                                    <div className="mb-2">
                                                                        <div className="form-group">
                                                                            <Field as={ThemedForm.Control} type="text" name={`fornituras.${index}.consumoAnnuo`} placeholder="Consumo Annuo" />
                                                                            {touched.fornituras?.[index]?.consumoAnnuo && errors.fornituras?.[index]?.consumoAnnuo && <div className="invalid-feedback d-block">{errors.fornituras?.[index]?.consumoAnnuo}</div>}
                                                                        </div>
                                                                    </div>

                                                                    <div className="mb-2">
                                                                        <div className="form-group">
                                                                            <Field as={ThemedForm.Control} type="text" name={`fornituras.${index}.indirizzo`} placeholder="Indirizzo" />
                                                                        </div>
                                                                    </div>

                                                                    <div className="mb-2">
                                                                        <div className="form-group">
                                                                            <Field as={ThemedForm.Control} type="text" name={`fornituras.${index}.provincia`} placeholder="Provincia" />
                                                                            {touched.fornituras?.[index]?.provincia && errors.fornituras?.[index]?.provincia && <div className="invalid-feedback d-block">{errors.fornituras?.[index]?.provincia}</div>}
                                                                        </div>
                                                                    </div>

                                                                    <div className="mb-2">
                                                                        <div className="form-group">
                                                                            <Field as={ThemedForm.Control} type="text" name={`fornituras.${index}.cap`} placeholder="CAP" />
                                                                        </div>
                                                                    </div>

                                                                    <div className="mb-2">
                                                                        <div className="form-group">
                                                                            <Field as={ThemedForm.Control} type="text" name={`fornituras.${index}.telephone1`} placeholder="Telefono Principale" />
                                                                            {touched.fornituras?.[index]?.telephone1 && errors.fornituras?.[index]?.telephone1 && <div className="invalid-feedback d-block">{errors.fornituras?.[index]?.telephone1}</div>}
                                                                        </div>
                                                                    </div>

                                                                    <div className="mb-2">
                                                                        <div className="form-group">
                                                                            <Field as={ThemedForm.Control} type="text" name={`fornituras.${index}.telephone2`} placeholder="Telefono Aggiuntivo" />
                                                                            {touched.fornituras?.[index]?.telephone2 && errors.fornituras?.[index]?.telephone2 && <div className="invalid-feedback d-block">{errors.fornituras?.[index]?.telephone2}</div>}
                                                                        </div>
                                                                    </div>

                                                                    <div className="mb-2">
                                                                        <div className="form-group">
                                                                            <Field
                                                                                as="select"
                                                                                className="form-control"
                                                                                name={`fornituras.${index}.offerta`}
                                                                            >
                                                                                <option value="">Seleziona Offerta</option>
                                                                                {
                                                                                    offers.filter( offer => {


                                                                                        if(offer.type_contract === "RESIDENZIALI") {
                                                                                            if(values.tipologia === "consumer") {
                                                                                                if(values.fornituras[index].purpose === "gas") {
                                                                                                    if(offer.type === "GAS") {
                                                                                                        return true;
                                                                                                    } else {
                                                                                                        return false;
                                                                                                    }
                                                                                                } else if(values.fornituras[index].purpose === "luce") {
                                                                                                    if(offer.type === "POWER") {
                                                                                                        return true;
                                                                                                    } else {
                                                                                                        return false;
                                                                                                    }
                                                                                                }
                                                                                            } else {
                                                                                                return false;
                                                                                            }
                                                                                        } else if(offer.type_contract === "BUSINESS") {
                                                                                            if(values.tipologia === "business") {
                                                                                                if(values.fornituras[index].purpose === "gas") {
                                                                                                    if(offer.type === "GAS") {
                                                                                                        return true;
                                                                                                    } else {
                                                                                                        return false;
                                                                                                    }
                                                                                                } else if(values.fornituras[index].purpose === "luce") {
                                                                                                    if(offer.type === "POWER") {
                                                                                                        return true;
                                                                                                    } else {
                                                                                                        return false;
                                                                                                    }
                                                                                                }
                                                                                            } else {
                                                                                                return false;
                                                                                            }
                                                                                        }

                                                                                        return false;
                                                                                    })
                                                                                    .map( (offer, index) => (<option value={offer.value} key={index}>{offer.value}</option>) )
                                                                                }
                                                                            </Field>
                                                                            {touched.fornituras?.[index]?.offerta && errors.fornituras?.[index]?.offerta && <div className="invalid-feedback d-block">{errors.fornituras?.[index]?.offerta}</div>}
                                                                        </div>
                                                                    </div>

                                                                    {
                                                                        values.tipologia === "consumer" &&
                                                                         <>
                                                                            <div className="mb-2">
                                                                                <div className="form-group">
                                                                                    <Field as={ThemedForm.Control} type="text" name={`fornituras.${index}.nomeCognome`} placeholder="Nome e Cognome" />
                                                                                </div>
                                                                            </div>

                                                                            <div className="mb-2">
                                                                                <div className="form-group">
                                                                                    <Field as={ThemedForm.Control} type="text" name={`fornituras.${index}.codiceFiscale`} placeholder="Codice Fiscale" />
                                                                                    {touched.fornituras?.[index]?.codiceFiscale && errors.fornituras?.[index]?.codiceFiscale && <div className="invalid-feedback d-block">{errors.fornituras?.[index]?.codiceFiscale}</div>}
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                        
                                                                    }

                                                                    {
                                                                        values.tipologia === "business" &&
                                                                        <>
                                                                            <div className="mb-2">
                                                                                <div className="form-group">
                                                                                    <Field as={ThemedForm.Control} type="text" name={`fornituras.${index}.denominazione`} placeholder="Ragione Sociale" />
                                                                                </div>
                                                                            </div>
                                                                            <div className="mb-2">
                                                                                <div className="form-group">
                                                                                    <Field as={ThemedForm.Control} type="text" name={`fornituras.${index}.piva`} placeholder="P. IVA" />
                                                                                    {touched.fornituras?.[index]?.piva && errors.fornituras?.[index]?.piva && <div className="invalid-feedback d-block">{errors.fornituras?.[index]?.piva}</div>}
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    }

                                                                    <div className="mb-2">
                                                                        <div className="form-group">
                                                                            <Field component={Switch} LabelAs="h5" name={`fornituras.${index}.isDigital`} label="Is Digital" />
                                                                            {touched.fornituras?.[index]?.isDigital && errors.fornituras?.[index]?.isDigital && <div className="invalid-feedback d-block">{errors.fornituras?.[index]?.isDigital}</div>}
                                                                        </div>
                                                                    </div>

                                                                    {
                                                                        values.fornituras[index].isDigital ?
                                                                        <>
                                                                            <div className="mb-2">
                                                                                <div className="form-group">
                                                                                    <h5>Data di nascita</h5>
                                                                                    <Field as={ThemedForm.Control} type="date" name={`fornituras.${index}.dob`} placeholder="Data di nascita" />
                                                                                    {touched.fornituras?.[index]?.dob && errors.fornituras?.[index]?.dob && <div className="invalid-feedback d-block">{errors.fornituras?.[index]?.dob}</div>}
                                                                                </div>
                                                                            </div>
                                                                            <div className="mb-2">
                                                                                <div className="form-group">
                                                                                    <Field as={ThemedForm.Control} type="text" name={`fornituras.${index}.birthPlace`} placeholder="Luogo di nascita" />
                                                                                    {touched.fornituras?.[index]?.birthPlace && errors.fornituras?.[index]?.birthPlace && <div className="invalid-feedback d-block">{errors.fornituras?.[index]?.birthPlace}</div>}
                                                                                </div>
                                                                            </div>
                                                                            <div className="mb-2">
                                                                                <div className="form-group">
                                                                                    <h5>Documento</h5>
                                                                                    <Field as={ThemedForm.Check} type="radio" name={`fornituras.${index}.document`} value="Carta d'identità" label="Carta d'identità" className="d-inline-block" />
                                                                                    <Field as={ThemedForm.Check} type="radio" name={`fornituras.${index}.document`} value="Patente di guida" label="Patente di guida" className="d-inline-block mx-2" />
                                                                                    <Field as={ThemedForm.Check} type="radio" name={`fornituras.${index}.document`} value="Passaporto" label="Passaporto" className="d-inline-block" />
                                                                                    {touched.fornituras?.[index]?.document && errors.fornituras?.[index]?.document && <div className="invalid-feedback d-block">{errors.fornituras?.[index]?.document}</div>}
                                                                                </div>
                                                                            </div>
                                                                            <div className="mb-2">
                                                                                <div className="form-group">
                                                                                    <Field as={ThemedForm.Control} type="text" name={`fornituras.${index}.documentNumber`} placeholder="Numero del documento" />
                                                                                    {touched.fornituras?.[index]?.documentNumber && errors.fornituras?.[index]?.documentNumber && <div className="invalid-feedback d-block">{errors.fornituras?.[index]?.documentNumber}</div>}
                                                                                </div>
                                                                            </div>

                                                                            <div className="mb-2">
                                                                                <div className="form-group">
                                                                                    <h5>Data di rilascio</h5>
                                                                                    <Field as={ThemedForm.Control} type="date" name={`fornituras.${index}.releaseDate`} placeholder="Data di rilascio" />
                                                                                    {touched.fornituras?.[index]?.releaseDate && errors.fornituras?.[index]?.releaseDate && <div className="invalid-feedback d-block">{errors.fornituras?.[index]?.releaseDate}</div>}
                                                                                </div>
                                                                            </div>

                                                                            <div className="mb-2">
                                                                                <div className="form-group">
                                                                                    <h5>Data di scadenza</h5>
                                                                                    <Field as={ThemedForm.Control} type="date" name={`fornituras.${index}.expirationDate`} placeholder="Data di scadenza" />
                                                                                    {touched.fornituras?.[index]?.expirationDate && errors.fornituras?.[index]?.expirationDate && <div className="invalid-feedback d-block">{errors.fornituras?.[index]?.expirationDate}</div>}
                                                                                </div>
                                                                            </div>

                                                                            <div className="mb-2">
                                                                                <div className="form-group">
                                                                                    <Field as={ThemedForm.Control} type="text" name={`fornituras.${index}.currentSupplier`} placeholder="Fornitore attuale" />
                                                                                    {touched.fornituras?.[index]?.currentSupplier && errors.fornituras?.[index]?.currentSupplier && <div className="invalid-feedback d-block">{errors.fornituras?.[index]?.currentSupplier}</div>}
                                                                                </div>
                                                                            </div>

                                                                            <div className="mb-2">
                                                                                <div className="form-group">
                                                                                    <Field as={ThemedForm.Control} type="text" name={`fornituras.${index}.residentialAddress`} placeholder="Indirizzo di residenza" />
                                                                                    {touched.fornituras?.[index]?.residentialAddress && errors.fornituras?.[index]?.residentialAddress && <div className="invalid-feedback d-block">{errors.fornituras?.[index]?.residentialAddress}</div>}
                                                                                </div>
                                                                            </div>

                                                                            <div className="mb-2">
                                                                                <div className="form-group">
                                                                                    <h5>Proprietà dell'immobile</h5>
                                                                                    <Field as={ThemedForm.Check} type="radio" name={`fornituras.${index}.propertyOwnership`} value="Proprietario" label="Proprietario" className="d-inline-block" />
                                                                                    <Field as={ThemedForm.Check} type="radio" name={`fornituras.${index}.propertyOwnership`} value="Locatario" label="Locatario" className="d-inline-block mx-2" />
                                                                                    <Field as={ThemedForm.Check} type="radio" name={`fornituras.${index}.propertyOwnership`} value="Altro" label="Altro" className="d-inline-block" />
                                                                                    {touched.fornituras?.[index]?.propertyOwnership && errors.fornituras?.[index]?.propertyOwnership && <div className="invalid-feedback d-block">{errors.fornituras?.[index]?.propertyOwnership}</div>}
                                                                                </div>
                                                                            </div>

                                                                            <div className="mb-2">
                                                                                <div className="form-group">
                                                                                    <Field component={Switch} LabelAs="h5" name={`fornituras.${index}.resident`} label="Residente" />
                                                                                    {touched.fornituras?.[index]?.resident && errors.fornituras?.[index]?.resident && <div className="invalid-feedback d-block">{errors.fornituras?.[index]?.resident}</div>}
                                                                                </div>
                                                                            </div>

                                                                            <div className="mb-2">
                                                                                <div className="form-group">
                                                                                    <Field as={ThemedForm.Control} type="text" name={`fornituras.${index}.currentAccountHolder`} placeholder="Nome, Cognome, Codice Fiscale dell'Intestatario Iban" />
                                                                                    {touched.fornituras?.[index]?.currentAccountHolder && errors.fornituras?.[index]?.currentAccountHolder && <div className="invalid-feedback d-block">{errors.fornituras?.[index]?.currentAccountHolder}</div>}
                                                                                </div>
                                                                            </div>

                                                                            <div className="mb-2">
                                                                                <div className="form-group">
                                                                                    <Field as={ThemedForm.Control} type="text" name={`fornituras.${index}.ibanCoordinates`} placeholder="Coordinate IBAN" />
                                                                                    {touched.fornituras?.[index]?.ibanCoordinates && errors.fornituras?.[index]?.ibanCoordinates && <div className="invalid-feedback d-block">{errors.fornituras?.[index]?.ibanCoordinates}</div>}
                                                                                </div>
                                                                            </div>

                                                                            <div className="mb-2">
                                                                                <div className="form-group">
                                                                                    <h5>Tipologia Societa</h5>
                                                                                    <Field as={ThemedForm.Check} type="radio" name={`fornituras.${index}.tipologiaSocieta`} value="SAS" label="SAS" className="d-inline-block" />
                                                                                    <Field as={ThemedForm.Check} type="radio" name={`fornituras.${index}.tipologiaSocieta`} value="SNC" label="SNC" className="d-inline-block mx-2" />
                                                                                    <Field as={ThemedForm.Check} type="radio" name={`fornituras.${index}.tipologiaSocieta`} value="SRL" label="SRL" className="d-inline-block me-2" />
                                                                                    <Field as={ThemedForm.Check} type="radio" name={`fornituras.${index}.tipologiaSocieta`} value="SPA" label="SPA" className="d-inline-block me-2" />
                                                                                    <Field as={ThemedForm.Check} type="radio" name={`fornituras.${index}.tipologiaSocieta`} value="Ditta Individuale" label="Ditta Individuale" className="d-inline-block" />
                                                                                    {touched.fornituras?.[index]?.tipologiaSocieta && errors.fornituras?.[index]?.tipologiaSocieta && <div className="invalid-feedback d-block">{errors.fornituras?.[index]?.tipologiaSocieta}</div>}
                                                                                </div>
                                                                            </div>

                                                                            <div className="mb-2">
                                                                                <div className="form-group">
                                                                                    <Field as={ThemedForm.Control} type="email" name={`fornituras.${index}.email`} placeholder="E-mail Address" />
                                                                                    {touched.fornituras?.[index]?.email && errors.fornituras?.[index]?.email && <div className="invalid-feedback d-block">{errors.fornituras?.[index]?.email}</div>}
                                                                                </div>
                                                                            </div>

                                                                        </>
                                                                        : null
                                                                    }
                                                            
                                                                    

                                                                </div>
                                                            );
                                                            
                                                        }
                                                    )}
                                                    <div className="text-end">
                                                        <Button className="my-3" type="button" onClick={ () => !!values.tipologia && push({ cap: "", consumoAnnuo: "", indirizzo: "", kw: "", offerta: "", piva: "", pod: "", purpose: "", telephone1: "", telephone2: "", mc: "", pdr: "", nomeCognome: "", denominazione: "", codiceFiscale: "", provincia: "", isDigital: false, dob: "", birthPlace: "", document: "", documentNumber: "", releaseDate: "", expirationDate: "", currentSupplier: "", residentialAddress: "", propertyOwnership: "", resident: false, currentAccountHolder: "", ibanCoordinates: "", tipologiaSocieta: "", email: "" }) }>Aggiungi Fornitura</Button>
                                                    </div>
                                                </div>
                                            )
                                        }
                                        </FieldArray>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        {/* <Field as={ThemedForm.Control} value={''} onChange={ (event) => handleContractUpload(event, setFieldValue) } className="form-control" type="file" name="contract" /> */}

                                        <Dashboard
                                            uppy={uppy}
                                            width="100%"
                                            height="300px"
                                            showRemoveButtonAfterComplete={true}
                                            hideUploadButton={true}
                                            hideRetryButton={true}
                                            hidePauseResumeButton={true}
                                            hideCancelButton={true}
                                            hideProgressAfterFinish={true}
                                            locale={{
                                                strings: {
                                                    addMoreFiles: 'Upload more files',
                                                    addingMoreFiles: 'Uploading more files',
                                                    done: 'Finish',
                                                    back: 'Back',
                                                    removeFile: 'Remove file',
                                                    editFile: 'Edit file',
                                                    editing: 'Editing %{file}',
                                                    edit: 'Edit',
                                                    finishEditingFile: 'Finish editing file',
                                                    saveChanges: 'Save changes',
                                                    myDevice: 'My Device',
                                                    dropPaste: 'Carica i file qui, oppure %{browse}',
                                                    browse: 'cerca',
                                                    uploadComplete: 'Upload complete',
                                                    retryUpload: 'Retry upload',
                                                    xFilesSelected: {
                                                        0: '%{smart_count} file selected',
                                                        1: '%{smart_count} files selected'
                                                    },
                                                    poweredBy2: '',
                                                    uploading: 'Uploading',
                                                    complete: 'Complete'
                                                }
                                            }}
                                        />
                                        
                                    </Col>
                                </Row>

                                <Row className="py-2">
                                    <Col>
                                        <Field as={ThemedForm.Control} type="text" name="plico" placeholder="Plico" />
                                        {touched.plico && errors.plico && <div className="invalid-feedback d-block">{errors.plico}</div>}
                                    </Col>
                                </Row>
                                <Row className="py-2">
                                    <Col className="mt-3" lg={12} md={12} sm={6} xs={6}>
                                        <Field component={Switch} name="fatturazione" label="Fattura" />
                                    </Col>
                                    <Col className="mt-3" lg={12} md={12} sm={6} xs={6}>
                                        <Field component={Switch} name="rid" label="RID" />
                                    </Col>
                                </Row>
                                <Row className="py-2">
                                    <Col className="mt-3" lg={12} md={12} sm={6} xs={6}>
                                        <Field component={Switch} name="bw" label="BW" />
                                    </Col>
                                    <Col className="mt-3" lg={12} md={12} sm={6} xs={6}>
                                        <Field component={Switch} name="ev" label="EV" />
                                    </Col>
                                </Row>
                                <Row className="py-2">
                                    <Col className="mt-3" lg={12} md={12} sm={6} xs={6}>
                                        <Field component={Switch} name="cs" label="CS" />
                                    </Col>
                                </Row>
                                <Row className="py-2">
                                    <Col>
                                        <Field as='textarea' className="form-control" type="text" name="note" placeholder="Note" />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="text-end">
                                        <Button className="my-3" type="submit">Inserisci Contratto</Button>
                                    </Col>
                                </Row>
                            </Container>
                        </Form>
                    )
                }
            </Formik>
        </ValidationForm>
    );
}

const Switch = ({ field, form, label, LabelAs = "h4", ...props }) => {
    return (
        <>
            { label ? <LabelAs>{label}</LabelAs> : null }
            <div className="form-check form-switch d-flex align-items-center px-0">
                <label className="form-check-label" htmlFor="fatturazione">No</label>
                <input className="form-check-input float-none mx-2" {...field} {...props} checked={!!field.value} value="" type="checkbox" name={field.name} id="fatturazione" />
                <label className="form-check-label" htmlFor="fatturazione">Si</label>
            </div>
        </>
    )
}
