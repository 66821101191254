import React from 'react';
import Header from '../Header';

export default function Form({ logo, name, children }) {

  return (
    <div>
      <div className="gradient-background">
          <div className="row h-100 mx-0 flex-column align-items-center justify-content-center">
              <div className="col-lg-5 col-sm-12">
                  <div className="gradient-header">
                      <img className="gradient-header-logo img-fluid" src={logo} alt={name} />
                  </div>
              </div>
              <Header />
              <div className="col-lg-9 col-sm-12">
                  <div className="gradient-container">
                    { children }
                  </div>
              </div>
          </div>
      </div>
    </div>
  );
}