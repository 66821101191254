import { useState } from 'react'
import { authenticationServerURL } from '../../config/api'
import { useHistory, Redirect } from 'react-router-dom';
import logo from '../../assets/fidasi-logo.png'
import Swal from "sweetalert2";

const Login = () => {

    const history = useHistory();
    const accessToken = localStorage.getItem('accessToken');

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    const handleLogin = async (e) => {
        try {
            e.preventDefault();
            setLoading(true);
            if(email.length < 3 || password.length < 3) {
                throw new Error('Si prega di inserire informazioni valide');
            }
            const data = new URLSearchParams();
            data.append('client_id', 1);
            data.append('client_secret', 123);
            data.append('username', email);
            data.append('password', password);
            data.append('grant_type', 'password');

            setError('');
            const loginAPIRequest = await fetch(`${authenticationServerURL}/oauth/token`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: data.toString()
            });
            setLoading(false);
            const loginAPIResponse = await loginAPIRequest.json();

            if(loginAPIResponse.statusCode && loginAPIResponse.statusCode !== 200) {
                throw new Error('Username o password non corretti.');
            }

            if(loginAPIResponse.accessToken) {
                setError('');
                setLoading(false);
                localStorage.setItem('accessToken', JSON.stringify({
                    accessToken: loginAPIResponse.accessToken,
                    accessTokenExpiresAt: loginAPIResponse.accessTokenExpiresAt
                }));
                history.push('/');
            }
        } catch(e) {
            setLoading(false);
            Swal.fire("Errore", e.message, "error");
        }
    }

    if(accessToken) {
        return <Redirect to='/' />
    }

    return (
        <div className="gradient-background">
            <div className="row h-100 mx-0 flex-column align-items-center justify-content-center">
                <div className="col-lg-5 col-sm-12">
                    <div className="gradient-header">
                        <img className="gradient-header-logo img-fluid" src={logo} alt="FidaSi" />
                    </div>
                </div>
                <div className="col-lg-5 col-sm-12">
                    <div className="gradient-container mt-5">
                        <h1 className="mb-3">Login</h1>
                        <form onSubmit={ (e) => handleLogin(e) }>
                            {/* { error ? <p className="form-error">{error}</p> : null } */}
                            <div className="mb-3">
                                <label htmlFor="email" className="form-label">Email address</label>
                                <input className="form-control" type="text" id="email" onChange={ (e) => setEmail(e.target.value) } />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="password" className="form-label">Password</label>
                                <input className="form-control" type="password" id="password" onChange={ (e) => setPassword(e.target.value) } />
                            </div>
                            <div className="text-center">
                                <button className="btn btn-primary" type="submit" disabled={loading}>Login</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login