import React from 'react';
import { Link } from "react-router-dom";
import Header from '../Header';
import fidasiLogo from '../../assets/fidasi-logo.png'
import enelLogo from '../../assets/enel-logo.png'
import enelComparatoreLogo from '../../assets/enel-comparatore-logo.png'
import fontElLogo from '../../assets/fontel-logo.png'
import eniLogo from '../../assets/eni-logo.png'
import irenLogo from '../../assets/iren-logo.png'
import iberdrolaLogo from '../../assets/iberdrola-logo.png'
import optimaLogo from '../../assets/optima-logo.jpg'
import vodafonaLogo from '../../assets/vodafone-logo.png'
import lycamobileLogo from '../../assets/lycamobile-logo.png'
import gaswayLogo from '../../assets/gasway-logo.png'
import edisonLogo from '../../assets/edison-logo.png';

export default function Home() {

    return (
        <div>
            <div className="gradient-background">
                <div className="row h-100 mx-0 flex-column align-items-center justify-content-center">
                    <div className="col-lg-5 col-sm-12">
                        <div className="gradient-header">
                            <img className="gradient-header-logo img-fluid" src={fidasiLogo} alt="FidaSi" />
                        </div>
                    </div>
                    <Header />
                    <div className="col-lg-5 col-sm-12">
                        <div className="gradient-header">
                            <Link to="/enel">
                                <img className="gradient-header-logo img-fluid" src={enelLogo} alt="ENEL" />
                            </Link>
                        </div>
                        <div className="gradient-header">
                            <Link to="/enelcomparatore">
                                <img className="gradient-header-logo img-fluid" src={enelComparatoreLogo} alt="ENEL Comparatore" />
                            </Link>
                        </div>
                        <div className="gradient-header">
                            <Link to="/fontel">
                                <img className="gradient-header-logo img-fluid" src={fontElLogo} alt="FontEl" />
                            </Link>
                        </div>

                        <div className="gradient-header">
                            <Link to="/optima">
                                <img className="gradient-header-logo img-fluid" src={optimaLogo} alt="Optima" />
                            </Link>
                        </div>

                        <div className="gradient-header">
                            <Link to="/iren">
                                <img className="gradient-header-logo img-fluid" src={irenLogo} alt="Iren" />
                            </Link>
                        </div>

                        <div className="gradient-header">
                            <Link to="/eni">
                                <img className="gradient-header-logo img-fluid" src={eniLogo} alt="ENI" />
                            </Link>
                        </div>

                        <div className="gradient-header">
                            <Link to="/iberdrola">
                                <img className="gradient-header-logo img-fluid" src={iberdrolaLogo} alt="Iberdrola" />
                            </Link>
                        </div>

                        <div className="gradient-header">
                            <Link to="/vodafone">
                                <img className="gradient-header-logo img-fluid" src={vodafonaLogo} alt="Vodafone" />
                            </Link>
                        </div>

                        <div className="gradient-header">
                            <Link to="/lycamobile">
                                <img className="gradient-header-logo img-fluid" src={lycamobileLogo} alt="Lycamobile" />
                            </Link>
                        </div>

                        <div className="gradient-header">
                            <Link to="/gasway">
                                <img className="gradient-header-logo img-fluid" src={gaswayLogo} alt="Gasway" />
                            </Link>
                        </div>

                        <div className="gradient-header">
                            <Link to="/edison">
                                <img className="gradient-header-logo img-fluid" src={edisonLogo} alt="Edison" />
                            </Link>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}






