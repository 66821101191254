import './App.css';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import Home from './components/Home';
import Login from './components/Login';
import Enel from './components/forms/Enel';
import Fontel from './components/forms/Fontel';
import Optima from './components/forms/Optima';
import Iren from './components/forms/Iren';
import ENI from './components/forms/ENI';
import IBERDROLA from './components/forms/IBERDROLA';
import Vodafone from './components/forms/Vodafone';
import Lycamobile from './components/forms/Lycamobile';
import Gasway from './components/forms/Gasway';
import Edison from './components/forms/Edison';

import moment from 'moment';
import { authenticationServerURL } from './config/api';
import EnelComparatore from './components/forms/EnelComparatore';

function ProtectedRoute(props) {
  const Component = props.component;
  let token = localStorage.getItem('accessToken');
  let status = false;
  if(token && token !== "") {
    token = JSON.parse(token);
    status = moment(new Date(token.accessTokenExpiresAt)).isAfter(new Date());
  }
  if(status === false) {
    localStorage.removeItem('accessToken');
  }
  let isAuthenticated = status;

  if(isAuthenticated) {
    (async () => {
      try {
        const tokenVerificationAPI = await fetch(`${authenticationServerURL}/oauth/verify/token`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            token: token.accessToken
          })
        });
        const tokenVerificationAPIResponse = await tokenVerificationAPI.json();
        if(tokenVerificationAPIResponse.valid !== true) {
          localStorage.removeItem('accessToken');
        }
      } catch(e) {
        isAuthenticated = false;
        localStorage.removeItem('accessToken');
      }
    })();
  }
  return isAuthenticated ? <Component /> : <Redirect to={{ pathname: '/login' }} />;
}


function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/login" component={Login} />
        {/* <Route exact path="/old" component={OldForm} /> */}
        <ProtectedRoute exact path="/" component={Home} />
        <ProtectedRoute exact path="/enel" component={Enel} />
        <ProtectedRoute exact path="/enelcomparatore" component={EnelComparatore} />
        <ProtectedRoute exact path="/fontel" component={Fontel} />
        <ProtectedRoute exact path="/optima" component={Optima} />
        <ProtectedRoute exact path="/iren" component={Iren} />
        <ProtectedRoute exact path="/eni" component={ENI} />
        <ProtectedRoute exact path="/iberdrola" component={IBERDROLA} />
        <ProtectedRoute exact path="/vodafone" component={Vodafone} />
        <ProtectedRoute exact path="/lycamobile" component={Lycamobile} />
        <ProtectedRoute exact path="/gasway" component={Gasway} />
        <ProtectedRoute exact path="/edison" component={Edison} />
      </Switch>
    </Router>
  );
}

export default App;
