import React from 'react';
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import { backOfficeURL } from '../../config/api';

export default function Header() {

  const history = useHistory();

  const handleLogout = async (e) => {
      e.preventDefault();
  
      const confirmationResponse = await Swal.fire({
        title: 'Vuoi davvero uscire?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sì',
        cancelButtonText: 'No',
        confirmButtonClass: 'btn btn-danger',
        cancelButtonClass: 'btn btn-secondary',
        buttonsStyling: false
      });
  
      if(confirmationResponse.isConfirmed === true) {
        localStorage.clear();
        return history.push('/');
      }
  
  
  }

  return (
    <div className="col-lg-9 col-sm-12">
      <div className="d-flex align-items-center justify-content-evenly action-row mx-0 my-5">
          <a href={backOfficeURL} target="_blank" rel="noreferrer" className="btn btn-primary bg-white text-primary btn-block primary-action-btn" type="button">
              Login Backoffice
          </a>
          <button onClick={ (e) => handleLogout(e) } className="btn btn-outline-primary border-white btn-block text-white" type="button">Logout</button>
      </div>
    </div>
  )
}